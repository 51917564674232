body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F4F6F8 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Poppins";
  font-weight: 100 200 300 400;
  font-style: normal;
  src: url("_assets/fonts/Poppins/Poppins-Regular.ttf"),
    url("_assets/fonts/Poppins/Poppins-Regular.woff"),
    url("_assets/fonts/Poppins/Poppins-Regular.woff2");
}

@font-face {
  font-family: "Poppins";
  font-weight: 500;
  font-style: normal;
  src: url("_assets/fonts/Poppins/Poppins-Medium.ttf"),
    url("_assets/fonts/Poppins/Poppins-Medium.woff"),
    url("_assets/fonts/Poppins/Poppins-Medium.woff2");
}

@font-face {
  font-family: "Poppins";
  font-weight: 600;
  font-style: normal;
  src: url("_assets/fonts/Poppins/Poppins-SemiBold.ttf"),
    url("_assets/fonts/Poppins/Poppins-SemiBold.woff"),
    url("_assets/fonts/Poppins/Poppins-SemiBold.woff2");
}

@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  src: url("_assets/fonts/Poppins/Poppins-Bold.ttf"),
    url("_assets/fonts/Poppins/Poppins-Bold.woff"),
    url("_assets/fonts/Poppins/Poppins-Bold.woff2");
}

@font-face {
  font-family: "IBM Plex Sans Arabic";
  font-weight: 100;
  font-style: normal;
  src: url("_assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Thin.ttf"),
    url("_assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Thin.woff"),
    url("_assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Thin.woff2");
}

@font-face {
  font-family: "IBM Plex Sans Arabic";
  font-weight: 200;
  font-style: normal;
  src: url("_assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-ExtraLight.ttf"),
    url("_assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-ExtraLight.woff"),
    url("_assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-ExtraLight.woff2");
}

@font-face {
  font-family: "IBM Plex Sans Arabic";
  font-weight: 300;
  font-style: normal;
  src: url("_assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Light.ttf"),
    url("_assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Light.woff"),
    url("_assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Light.woff2");
}

@font-face {
  font-family: "IBM Plex Sans Arabic";
  font-weight: 400;
  font-style: normal;
  src: url("_assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Regular.ttf"),
    url("_assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Regular.woff"),
    url("_assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Regular.woff2");
}

@font-face {
  font-family: "IBM Plex Sans Arabic";
  font-weight: 500;
  font-style: normal;
  src: url("_assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Medium.ttf"),
    url("_assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Medium.woff"),
    url("_assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Medium.woff2");
}

@font-face {
  font-family: "IBM Plex Sans Arabic";
  font-weight: 600;
  font-style: normal;
  src: url("_assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-SemiBold.ttf"),
    url("_assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-SemiBold.woff"),
    url("_assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-SemiBold.woff2");
}

@font-face {
  font-family: "IBM Plex Sans Arabic";
  font-weight: 700;
  font-style: normal;
  src: url("_assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Bold.ttf"),
    url("_assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Bold.woff"),
    url("_assets/fonts/IBMPlexSansArabic/IBMPlexSansArabic-Bold.woff2");
}